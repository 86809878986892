.verified-tutor-dasboard-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--lightBackground);
  max-width: 100vw;
  height: calc(100vh - var(--headerHeight));
  margin-top: var(--headerHeight);
}

.vtd-page-title {
  text-align: center;
  margin: 0px 24px;
  margin-top: 8px;
}

.vtd-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 16px;
}

/* .vtd-side-card>h2 {
  font-size: 22px;
  font-weight: normal;
  color: var(--darkText);
  margin-bottom: 6px;
  text-align: center;
} */

.vtd-panel-switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  background: var(--mainWhite);
  border-radius: 8px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
  position: relative;
}

.vtd-panel-switch-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 28px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.vtd-psb-text {
  z-index: 2;
  color: var(--textMain);
  transition: all 0.2s ease-in-out;
}

.vtd-panel-switch-btn.active>.vtd-psb-text {
  color: var(--mainWhite);
}

.vtd-psb-badge {
  position: absolute;
  top: -5px;
  right: 8px;
  background: var(--primaryBlue);
  color: var(--mainWhite);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  z-index: 3;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0);
  transition: all 0.2s ease-in-out;
  font-weight: bold;
}

.vtd-panel-switch-btn.active>.vtd-psb-badge {
  background: var(--mainWhite);
  color: var(--primaryBlue);
  top: -5px;
  right: -4px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}

.vtd-panel-switch-selector {
  background: var(--primaryBlue);
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  position: absolute;
  height: 42px;
  box-shadow: 0px 0px 12px 0px rgba(1, 7, 43, 0.2);
}

.vtd-panel-switch-selector.all {
  width: 178px;
  left: 0;
}

.vtd-panel-switch-selector.pending {
  width: 228px;
  left: 180px;
}

.vtd-panel-switch-selector.profile {
  width: 138px;
  left: 410px;
}

.vtd-all-panel,
.vtd-pending-panel,
.vtd-profile-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.vtd-all-panel,
.vtd-pending-panel {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 20px;
}

@media only screen and (max-width: 580px) {
  .vtd-psb-text {
    font-size: 11px;
    color: var(--textMain);
    width: fit-content;
    text-align: center;
  }

  .vtd-panel-switch-container {
    margin: 26px 16px 0px 16px;
  }

  .vtd-panel-switch-selector {
    display: none;
  }

  .vtd-panel-switch-btn {
    width: fit-content;
    padding: 8px 16px;
  }

  .vtd-panel-switch-btn.active {
    background: var(--primaryBlue);
  }

  .vtd-page-title {
    font-size: 24px;
  }

  .vtd-psb-badge {
    font-size: 10px;
    width: 16px;
    height: 16px;
  }

  .vtd-psb-badge {
    top: -5px;
    right: 3px;
  }
}