.modal-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.18);
  z-index: 99;
  transition: all 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
}

.modal-background.active {
  opacity: 1;
  pointer-events: all;
}

.modal-background.navbar {
  opacity: 0;
  z-index: 40;
}

.modal-content {
  background-color: var(--lightBackground);
  padding: 20px 25px 25px 25px;
  border-radius: 12px;
  position: relative;
  min-width: 250px;
  max-width: 400px;
  width: 40vw;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  z-index: 10;
  opacity: 1;
  filter: blur(0);
  transform: translateY(-100%);
  transition: all 0.4s ease;
}

.modal-content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal-background.active>.modal-content {
  transform: translateY(0);
}

.modal-content.loading {
  opacity: 0;
  filter: blur(15px);
}

.modal-close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 6px;
  fill: var(--textMain);
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: all 0.25s ease;
  pointer-events: all;
}

.modal-close-icon:hover {
  transform: scale(1.1);
}

.modal-content h1 {
  margin-bottom: 24px;
  font-size: 24px;
  color: var(--textMain);
}

/************ Login modal ************/

.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-container>p {
  font-size: 16px;
  margin-bottom: 10px;
}

.multiple-choice-container {
  display: flex;
  flex-direction: column;
}

.mc-option {
  width: calc(100% - 20px);
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 8px 10px;
  border: 2px solid var(--borderGrey);
  transition: all 0.25s ease;
  cursor: pointer;
  opacity: 0.4;
}

.mc-option:hover {
  opacity: 0.7;
}

.mc-option.active {
  background-color: #4765f928;
  color: var(--primaryBlue);
  border: 2px solid var(--primaryBlue);
  opacity: 1;
  font-weight: normal;
}

.cm-login-next-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 8px 18px;
  background: var(--primaryBlue);
  border: none;
  border-radius: 6px;
  margin-top: 24px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.cm-login-next-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.cm-login-next-btn>p {
  color: var(--mainWhite);
  font-size: 16px;
}

.cm-login-next-btn>img {
  width: 16px;
  transition: all 0.25s ease;
  background: var(--mainWhite);
  padding: 4px;
  border-radius: 100px;
  margin-right: 12px;
}

.cm-login-next-btn:hover {
  transform: scale(1.04);
}

.login-text-input {
  width: calc(100% - 23px);
  height: fit-content;
  border-radius: 6px;
  border: 1.5px solid var(--borderGrey);
  background: transparent;
  padding: 6px 10px;
  font-size: 16px;
  font-weight: normal;
  color: var(--textMain);
  outline: none;
}

.login-text-input::placeholder {
  opacity: 1;
  color: var(--borderGrey);
}

.login-info-message {
  opacity: 0.7;
  font-size: 14px !important;
  margin-bottom: 14px !important;
}

.login-admin-p {
  font-size: 13px !important;
  margin-bottom: 0 !important;
  margin-top: 10px !important;
}

.login-admin-p>a {
  color: var(--primaryBlue);
  cursor: pointer;
}

@media only screen and (max-width: 650px) {
  .modal-content {
    width: 75%;
    max-width: 75%;
    min-width: none;
  }
}