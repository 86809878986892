.cc-status-icon-container {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    top: -40px;
}

.cc-status-icon-container.pending {
    background-color: var(--softYellow);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}

.cc-status-icon-container.tutoring {
    background-color: var(--softGreen);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}

@keyframes pulse {
    0% {
        width: 50%;
        height: 50%;
    }

    25% {
        width: 45%;
        height: 45%;
    }

    50% {
        width: 50%;
        height: 50%;
    }

    100% {
        width: 50%;
        height: 50%;
    }
}

.cc-status-icon {
    width: 50%;
    height: 50%;
}

.cc-status-icon.pending {
    fill: var(--warningYellow);
    animation: pulse 1.8s ease-in-out infinite;
}

.cc-status-icon.tutoring {
    fill: var(--successGreen);
    animation: none;
}

.cc-delete-btn,
.cc-delete-btn.tutoring.tutor {
    border-radius: 6px;
    padding: 8px 16px;
    cursor: pointer;
    opacity: 1;
    color: var(--errorRed);
    border: 0px solid var(--errorRed);
    background: var(--softRed);
    font-weight: bold;
    font-size: 14px;
    margin: 16px 0 20px 0;
    transition: all 0.15s ease;
}

.cc-delete-btn.tutor {
    background: none;
    margin: 4px 0 10px 0;
}

.cc-delete-btn.tutor:hover {
    transform: none;
}

.cc-delete-btn:hover {
    transform: scale(1.05);
}

.tpc-day.cc-connection-status {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
}

.tpc-day.cc-connection-status.pending {
    color: var(--warningYellow);
    background: var(--softYellow);
    border: 2px solid var(--warningYellow);
}

.tpc-day.cc-connection-status.tutoring {
    color: var(--successGreen);
    background: var(--softGreen);
    border: 2px solid var(--successGreen);
}

.tpc-accept-connection-button {
    border-radius: 6px;
    padding: 8px 16px;
    cursor: pointer;
    opacity: 1;
    color: var(--mainWhite);
    border: none;
    background: var(--primaryBlue);
    font-weight: bold;
    font-size: 14px;
    margin: 24px 0 0 0;
    width: 80%;
    transition: all 0.15s ease;
}

.tpc-accept-connection-button:hover {
    transform: scale(1.05);
}