.notification-container {
  position: fixed;
  top: -200px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primaryBlue);
  opacity: 0;
  transition: all 0.5s ease;
  width: max-content;
  height: fit-content;
  z-index: 9999;
  border-radius: 6px;
  max-width: 50%;
}

.notification-container.visible {
  opacity: 1;
  top: 30px;
}

.notification-container>p {
  margin: 8px 16px;
  font-size: 16px;
  color: var(--mainWhite);
  text-align: center;
}

.notification-container.success {
  background-color: var(--successGreen) !important;
}

.notification-container.error {
  background-color: var(--errorRed) !important;
}

@media only screen and (max-width: 700px) {
  .notification-container {
    max-width: 75%;
  }

  .notification-container.visible {
    top: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .notification-container {
    max-width: 90%;
  }
}