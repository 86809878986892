.student-dashboard-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: var(--lightBackground);
    min-height: calc(100vh - var(--headerHeight));
    max-width: 100vw;
    margin-top: var(--headerHeight);
    /* overflow-x: hidden; */
}

.sd-page-title {
    font-weight: bold;
    color: var(--darkText);
    margin-top: 16px;
    margin-bottom: 20px;
}

.sd-filter-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 75px;
    width: 100%;
}

.sd-tutor-results {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    height: fit-content;
    width: 1350px;
    margin-bottom: 30px;
    margin-top: 24px;
}

.sd-filter-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 12px;
    border: none;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
    padding: 8px 16px;
    font-size: 16px;
    width: 40%;
    margin-right: 16px;
    margin-left: 12px;
}

.sd-filter-search:focus {
    outline: none;
}

.sd-filter-option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-right: 12px;
    margin-left: 16px;
}

.sd-filter-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.sd-filter-option[id="avail"] {
    margin-right: 32px;
}

.sd-filter-option>p {
    margin-right: 10px;
    white-space: nowrap;
}

.sd-results-loading-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 20vh;
}

.find-tutors-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 75px;
    opacity: 0.45;
    padding: 0 30px;
}

.find-tutors-empty-state>h1 {
    margin-top: 12px;
    font-size: 38px;
    text-align: center;
}

.find-tutors-empty-state>p {
    margin-top: 12px;
    font-size: 20px;
    text-align: center;
}

.sd-filter-icon {
    height: 46px;
    width: 46px;
    justify-self: center;
    align-self: flex-start;
    margin-left: 18px;
    cursor: pointer;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
    transition: all 0.2s ease;
    display: none;
}

.sd-filter-icon:hover {
    transform: scale(1.075);
}

.sd-search-container {
    display: none;
}


@media only screen and (max-width: 580px) {
    .find-tutors-empty-state {
        margin: 75px 8px 0px 8px;
    }

    .find-tutors-empty-state>h1 {
        font-size: 24px;
    }

    .find-tutors-empty-state>p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 440px) {
    .sd-filter-option-container {
        flex-wrap: wrap;
    }

    .sd-filter-option[id="avail"] {
        margin-bottom: 14px;
        margin-right: 0px;
    }
}

@media only screen and (max-width: 1390px) {

    .sd-tutor-results,
    .sd-filter-row {
        width: 1008px;
    }
}

@media only screen and (max-width: 1030px) {
    .sd-filter-row {
        width: calc(100% - 120px);
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: fit-content;
    }

    .sd-filter-search[id="desktop"] {
        display: none;
    }

    .sd-search-container {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
    }

    .sd-filter-search {
        width: calc(100% - 32px);
        margin: 0;
        margin-bottom: 18px;
    }

    .sd-filter-option-container {
        margin-bottom: 0;
        width: 100%;
        justify-content: space-between;
        opacity: 0;
        max-height: 0;
        transition: all 0.25s ease;
    }

    .sd-filter-option-container.show {
        opacity: 1;
        max-height: 500px;
        margin-bottom: 10px;
    }

    .sd-filter-icon {
        display: block;
    }

    .sd-tutor-results,
    .sd-filter-row {
        width: 667px;
    }
}

@media only screen and (max-width: 700px) {
    .sd-page-title {
        font-size: 25px;
        text-align: center;
        margin: 16px 24px 20px 24px;
    }

    .sd-filter-row {
        width: calc(100% - 50px);
    }

    .sd-tutor-results {
        width: calc(100% - 100px);
    }
}