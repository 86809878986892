.under-construction-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--lightBackground);
    height: calc(100vh - var(--headerHeight));
    overflow-y: auto;
}

.uc-page-title {
    font-weight: bold;
    color: var(--mainText);
    font-size: 40px;
    margin-bottom: 20px;
}

.uc-page-subtitle {
    font-weight: normal;
    color: var(--mainText);
    font-size: 25px;
    margin-top: 20px;
}