.admin-dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  background: var(--lightBackground);
  height: calc(100vh - var(--headerHeight));
  margin-top: var(--headerHeight);
}

.manage-users-table,
.view-matches-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}