.navbar {
  max-width: 100vw;
  width: 100%;
  height: var(--headerHeight);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: var(--lightBackground);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  /* margin: 0;
  padding: 0; */
}

.navbar.dark {
  background: var(--primaryBlue);
}

.navbar.dark .navbar-logo-text,
.navbar.dark>.navbar-logo-container,
.navbar.dark .navbar-logo-img {
  color: var(--mainWhite);
  fill: var(--mainWhite);
}

.navbar-logo-text,
.navbar-logo-container {
  font-size: 22px;
  font-weight: bold;
  color: var(--textMain);
  text-decoration: none;
}

.navbar-logo-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
}

.navbar-logo-img {
  width: 30px;
  height: 30px;
  margin-right: 14px;
  fill: var(--textMain);
}

.navbar-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar.logo-hidden {
  justify-content: flex-end;
}

.navbar-link {
  margin-right: 15px;
  padding: 6px 16px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 18px;
  border: 2px solid transparent;
  text-decoration: none;
  transition: all 0.2s ease;
}

.navbar-link:hover {
  color: var(--primaryBlue);
}

.navbar.dark .navbar-link {
  color: var(--mainWhite);
}

#login {
  background-color: var(--primaryBlue);
  color: var(--mainWhite);
  margin-right: 25px !important;
  margin-left: 6px !important;
}

#logout {
  border: 2px solid var(--primaryBlue);
  color: var(--primaryBlue);
  margin-right: 25px !important;
  margin-left: 6px !important;
}

.navbar.dark #login,
.navbar.dark #logout {
  background-color: var(--primaryBlue);
  color: var(--mainWhite);
  border: 2px solid var(--mainWhite);
}

#login:hover,
#logout:hover {
  transform: scale(1.04);
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  margin-right: 16px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.hamburger-menu.active .bar1 {
  transform: rotate(45deg);
}

.hamburger-menu.active .bar2 {
  opacity: 0;
}

.hamburger-menu.active .bar3 {
  transform: rotate(-45deg);
}

.side-menu {
  position: fixed;
  right: -300px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--mainWhite);
  overflow: hidden;
  z-index: 200;
  -webkit-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
  display: none;
  /* opacity: 0; */
  top: calc(var(--headerHeight) - 12px);
  /* border: 2px solid var(--borderGrey); */
}

.side-menu.active {
  right: 24px !important;
  opacity: 1 !important;
}


.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 2.5px;
  background-color: var(--primaryBlue);
  margin: 4px;
  transition: 0.5s;
  border-radius: 100px;
  transform-origin: left;
}

.hamburger-menu.dark>.bar1,
.hamburger-menu.dark>.bar2,
.hamburger-menu.dark>.bar3 {
  background-color: var(--mainWhite);
}

.navbar-link.mobile {
  display: none;
}

.links,
.links-active {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  height: 100%;
}

.links-active li a {
  display: inline;
  margin: 0;
  height: 100%;
  color: var(--textWhite);
  text-decoration: none;
  padding: 12px 10vw;
  line-height: 24px;
  width: 100%;
  text-align: center;
}

.links-active li a:hover {
  color: var(--primaryBlue);
  background-color: var(--softBlue);
}

.links-active ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

@media only screen and (max-width: 800px) {

  .hamburger-menu,
  .navbar-link.mobile {
    display: flex;
  }

  .navbar-links-container {
    display: none;
  }

  .side-menu {
    display: block;
  }

  .navbar-link {
    font-size: 16px !important;
  }

  .links-active>ul>li {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 400px) {
  .navbar-logo-img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .navbar-logo-text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 300px) {
  .navbar-logo-text {
    display: none;
  }
}