.custom-table {
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  display: block;
  width: fit-content;
  height: 100%;
  /* overflow: auto; */
  margin: 0 auto;
}

.custom-table thead {
  background-color: var(--primaryBlue);
  position: sticky;
  top: 0;
  z-index: 1;
}

.custom-table tbody {
  width: 100%;
}

.custom-table tr {
  text-align: center;
}

.custom-table thead th {
  color: white;
  font-weight: bold;
  padding: 8px 16px;
  border-right: 1px solid var(--lightBackground);
  border-top: none;
  border-bottom: none;
}

.custom-table thead th:last-child {
  border-left: none;
}

.custom-table tbody td {
  padding: 8px 16px;
  border-right: 1px solid rgba(204, 204, 204, 0.7);
}

.custom-table tbody td:last-child {
  border-right: none;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #dee5fdb1;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: var(--lightBackground);
}

.custom-table tbody tr {
  transition: all 0.2s ease;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0);
  position: relative;
}

.custom-table tbody tr:hover {
  opacity: 1;
}

.ct-delete-button,
.ct-give-admin-button {
  border-radius: 8px;
  border: 1.5px solid var(--primaryBlue);
  background-color: var(--softBlue);
  color: var(--primaryBlue);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease;
  opacity: 0.85;
  padding: 4px 12px;
  margin: 3px;
  font-size: 14px;
}

.ct-delete-button {
  border: 1.5px solid var(--errorRed);
  color: var(--errorRed);
  background-color: var(--softRed);
}

.ct-give-admin-button.remove {
  border: 1.5px solid var(--errorRed);
  color: var(--errorRed);
  background-color: var(--softRed);
}

.ct-delete-button:hover,
.ct-give-admin-button:hover {
  transform: scale(1.05);
}

.custom-table td[id="editable"] {
  /* padding: 6px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: auto;
}

.ct-col-tag {
  border-radius: 100px;
  padding: 4px 12px;
  margin: 3px;
  font-size: 14px;
  background-color: transparent;
  color: var(--primaryBlue);
  border: 1.5px solid var(--primaryBlue);
}
