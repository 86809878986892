@media only screen and (max-width: 480px) {
    .select__indicator.select__dropdown-indicator {
        padding: 5px 6px 5px 0px;
    }
}

@media only screen and (max-width: 440px) {
    .select__indicator.select__dropdown-indicator {
        padding: 8px;
        padding-left: 4px;
    }
}