.not-found-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--headerHeight));
  background-color: var(--lightBackground);
}

.not-found-text {
  font-size: 60px;
  font-weight: bold;
  color: var(--borderGrey);
  margin-bottom: 10px;
  line-height: 135%;
  width: 100%;
  text-align: center;
  opacity: 0.5;
}
