.non-registered-tutor-screen,
.registered-tutor-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--lightBackground);
  height: calc(100vh - var(--headerHeight));
  overflow-y: auto;
  width: 100%;
  margin-top: var(--headerHeight);
}

.registered-tutor-screen {
  justify-content: flex-start;
  /* padding-top: 30px; */
}

.td-not-registered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  padding: 0 20px;
  padding-bottom: 30px;
  width: fit-content;
  max-width: 50vw;
  margin-bottom: 30px;
  top: calc(var(--headerHeight));
  position: absolute;
}

.tdnr-title {
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 10px;
  line-height: 135%;
  width: 100%;
  margin-bottom: 36px;
  text-align: center;
}

.multiple-choice-bubble-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

.mc-bubble {
  width: fit-content;
  border-radius: 200px;
  margin: 0px 4px 10px 4px;
  padding: 6px 12px;
  border: 2px solid var(--borderGrey);
  transition: all 0.25s ease;
  cursor: pointer;
  opacity: 0.4;
  font-size: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mc-bubble:hover {
  opacity: 0.7;
}

.mc-bubble.active {
  background-color: #4765f928;
  color: var(--primaryBlue);
  border: 2px solid var(--primaryBlue);
  opacity: 1;
}

.tdnr-continue-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 8px 18px;
  background: var(--primaryBlue);
  border: none;
  border-radius: 6px;
  margin-top: 18px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.tdnr-continue-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.tdnr-continue-btn>p {
  color: var(--mainWhite);
  font-size: 16px;
}

.tdnr-continue-btn>img {
  width: 30px;
  transition: all 0.25s ease;
  padding: 4px;
  border-radius: 100px;
  margin-right: 12px;
}

.tdnr-continue-btn:hover {
  transform: scale(1.04);
}

.td-not-registered-container .input-container>p {
  font-size: 17px !important;
  margin-bottom: 10px !important;
}

.td-not-verified-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - var(--headerHeight));
  overflow: hidden;
  background: var(--primaryBlue);
}

.td-nv-content-wrapper {
  background: var(--lightBackground);
  padding: 30px 50px;
  padding-top: 50px;
  border-radius: 12px;
  max-width: 600px;
  position: relative;
  margin: 0 24px;
}

.td-not-verified-container h1 {
  font-size: 40px;
  margin-bottom: 18px;
  z-index: 2;
  position: relative;
}

.td-not-verified-container h3 {
  font-size: 20px;
  margin-bottom: 16px;
  font-weight: normal;
  z-index: 2;
}

.td-nv-verification-icon {
  width: 75px;
  height: 75px;
  z-index: 2;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1190px) {
  .td-not-registered-container {
    max-width: 60vw;
  }
}

@media only screen and (max-width: 750px) {
  .input-container>p {
    text-align: center;
  }

  .td-not-registered-container {
    max-width: 75vw;
  }
}

@media only screen and (max-width: 500px) {
  .td-nv-content-wrapper>h1 {
    font-size: 28px;
    margin-bottom: 12px;
  }

  .td-nv-content-wrapper>h3 {
    font-size: 16px;
  }

  .td-nv-content-wrapper {
    padding: 25px 30px;
    padding-top: 40px;
  }

  .td-nv-verification-icon {
    width: 55px;
    height: 55px;
  }
}