.ad-fragment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - var(--sidebarWidth));
  height: 100%;
}

.ad-fragment-title {
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 14px;
}

.ad-fragment-inner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.connect-modal-message>i,
.warning-message>i {
  font-weight: bold;
}

.connect-modal-multiple-container,
.warning-multiple-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.connect-modal-option,
.warning-mc-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 20px);
  border-radius: 6px;
  margin: 8px 0;
  padding: 8px 10px;
  border: 2px solid var(--borderGrey);
  transition: all 0.25s ease;
  cursor: pointer;
  opacity: 1;
  text-align: center;
}

.connect-modal-option.continue,
.warning-mc-option.continue {
  margin-right: 16px;
  color: var(--errorRed);
  border: 2px solid var(--errorRed);
  background: #ff413b22;
  font-weight: bold;
}

.connect-modal-option.continue {
  background: #4765f928;
  color: var(--primaryBlue);
  border: 2px solid var(--primaryBlue);
}

.connect-modal-option.continue.disabled {
  opacity: 0.5;
}

.connect-modal-option:hover,
.warning-mc-option:hover {
  transform: scale(1.04);
}

.connect-modal-option.continue.disabled:hover {
  transform: none;
}