body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--lightBackground);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* #root {
  height: 100vh;
  overflow: auto;
} */

html {
  --primaryBlue: #4766f9;
  --softBlue: #dae1fd;
  --lightBackground: #f5f8fe;
  --mainWhite: #ffffff;
  --darkBackground: #1f272b;
  --softRed: #ff403b33;
  --softYellow: #FFF9E0;
  --softGreen: #E9F5EA;
  --textGrey: #787a8d;
  --textMain: #1e1f4b;
  --errorRed: #ff403b;
  --successGreen: #4bb543;
  --warningYellow: #ffd300;
  --borderGrey: #ccc;
  --headerHeight: 75px;
  --sidebarWidth: 80px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: rgba(204, 204, 204, 1);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(182, 182, 182);
}

#root {
  /* max-height: 100vh;
  overflow: hidden; */
}