.dashboard-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  background: var(--primaryBlue);
  width: 100%;
  max-width: var(--sidebarWidth);
  z-index: 99;
  transition: all 0.25s ease;
  /* border-radius: 0 12px 12px 0; */
}

.dashboard-sidebar:hover {
  max-width: 292px;
}

.ds-logo-img {
  width: 40px;
  height: 40px;
  fill: #7a90ff;
  margin-top: 24px;
  margin-bottom: 18px;
}

.ds-inner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 100%;
}

.ds-inner-content > div {
  margin: 0;
  padding: 0;
  width: 100%;
}

.ds-tab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  border-radius: 12px;
  background: var(--primaryBlue);
  z-index: 4;
  transition: all 0.25s ease;
  cursor: pointer;
  margin: 8px;
  overflow: hidden;
}

.ds-tab.active {
  background: #6b84fe;
}

.ds-tab:hover {
  background: #6b84fe;
}

.ds-tab > p {
  color: var(--mainWhite);
  font-size: 18px;
  font-weight: normal;
  padding: 18px 24px;
  position: absolute;
  top: 0;
  left: 42px;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: all 0.25s ease;
}

.dashboard-sidebar:hover .ds-tab > p {
  opacity: 1;
  pointer-events: all;
}

.ds-tab.active > p {
  color: var(--mainWhite);
}

.ds-tab-icon {
  fill: var(--mainWhite);
  width: 30px;
  margin: 18px;
  transition: all 0.25s ease;
}

.ds-tab.active .ds-tab-icon {
  fill: var(--mainWhite);
}
