.home-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--lightBackground);
  height: calc(100vh - var(--headerHeight));
  overflow-y: auto;
  margin-top: var(--headerHeight);
}

.home-screen::-webkit-scrollbar {
  width: 0px;
}

.home-header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  min-height: 50vh;
  padding-top: 3vw;
  background: var(--primaryBlue);
  position: relative;
  z-index: 2;
  width: 100%;
}

.home-section-divider {
  fill: var(--primaryBlue);
  width: 100vw;
  transform: rotateX(180deg) rotateY(180deg);
  margin-top: -3.5vw;
}

.home-column {
  display: flex;
  flex-direction: column;
}

.home-column[id="left"] {
  margin-left: 10vw;
  margin-right: 50px;
  width: 35%;
}

.home-column[id="right"] {
  width: 35%;
  align-items: center;
  justify-content: center;
  margin-right: 10vw;
  margin-left: 50px;
}

.home-banner-img {
  width: 95%;
  border-radius: 6px;
  transition: all 0.25s ease;
}

.home-banner-img:hover {
  transform: scale(1.025);
}

.home-header-title {
  font-size: 60px;
  font-weight: bold;
  color: var(--mainWhite);
  margin-bottom: 10px;
  line-height: 135%;
  width: 100%;
}

.home-header-subtitle {
  font-size: 24px;
  color: var(--mainWhite);
  margin: 10px 0;
  opacity: 0.8;
  width: 100%;
  font-weight: 400;
}

.home-get-started-btn {
  width: fit-content;
  height: 50px;
  border-radius: 6px;
  background-color: var(--primaryBlue);
  color: var(--mainWhite);
  font-size: 18px;
  font-weight: bold;
  border: 2px solid var(--mainWhite);
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0 24px;
  margin-top: 20px;
  align-self: flex-start;
}

.home-get-started-btn:hover {
  transform: scale(1.04);
}

.footer-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--textGrey);
  margin: 20px 0;
  position: relative;
}

.home-footer-logo {
  height: 100px;
  margin: 0 50px;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s ease;
}

.home-footer-logo:hover {
  opacity: 0.75;
}

.home-footer-credits-tag {
  position: absolute;
  bottom: -8px;
  right: 16px;
  opacity: 0.35;
}

@media only screen and (max-height: 650px) {
  .home-header-title {
    font-size: 45px;
  }

  .home-header-subtitle {
    font-size: 20px;
  }

  .home-get-started-btn {
    font-size: 18px;
    border: 2px solid var(--mainWhite);
    height: 48px;
    padding: 0 22px;
    margin-top: 18px;
  }
}

@media only screen and (max-width: 1290px) {
  .home-header-title {
    font-size: 48px;
  }

  .home-header-subtitle {
    font-size: 20px;
  }

  .home-banner-img {
    width: 400px;
    border-radius: 6px;
    transition: all 0.25s ease;
  }
}

@media only screen and (max-width: 1130px) {
  .home-header-title {
    font-size: 40px;
  }

  .home-header-subtitle {
    font-size: 20px;
  }

  .home-banner-img {
    width: 380px;
  }

  .home-get-started-btn {
    font-size: 16px;
    height: 42px;
    padding: 0 18px;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .home-header-content {
    min-height: 60vh;
  }
}

@media only screen and (max-width: 850px) {
  .home-header-title {
    font-size: 35px;
  }

  .home-header-subtitle {
    font-size: 20px;
  }

  .home-column[id="left"] {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 28px;
  }

  .home-column[id="right"] {
    display: none;
  }

  .home-get-started-btn {
    align-self: center;
  }

  .home-footer-logo {
    height: 90px;
    margin: 0;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.2s ease;
  }

  .footer-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 650px) {
  .home-footer-credits-tag {
    bottom: -8px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    opacity: 0.35;
  }

  .home-footer-logo {
    height: 75px;
  }
}