.app {
  width: 100vw;
  height: 100vh;
  background-color: var(--lightBackground);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  color: var(--textMain);
}
