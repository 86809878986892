.tpc-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 275px;
  background: var(--mainWhite);
  margin: 12px;
  border-radius: 16px;
  padding: 16px 24px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
  transform: translateY(0);
  transition: all 0.2s ease;
  /* min-height: 350px; */
}

.tpc-container.find-tutors {
  justify-self: center;
  align-self: center;
  margin: 0px;
}

.tpc-container.editable {
  width: fit-content;
  max-width: 50%;
  padding: 8px 40px;
  padding-top: 28px;
  margin-bottom: 28px;
  margin-top: 32px;
}

.tpc-inner-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  width: 100%;
}

.tpc-inner-content-container.loading {
  filter: blur(2.5px);
  pointer-events: none;
}

.tpc-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}

.tpc-loading-container.show {
  opacity: 1;
  pointer-events: all;
}

.tpc-container:hover {
  transform: translateY(-6px);
}

.tpc-container.editable:hover {
  transform: translateY(0);
}

.tpc-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.tpc-pfp {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: var(--lightBackground);
  /* box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1); */
  border: 2px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  object-fit: cover;
  text-align: center;
}

.tpc-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--darkText);
  margin-bottom: 6px;
  text-align: center;
}

.tpc-grade {
  font-size: 16px;
  margin-bottom: 10px;
}

.tpc-divider {
  width: 100%;
  height: 1.5px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.tpc-subtitle {
  font-size: 14px;
  color: var(--darkText);
  margin-bottom: 10px;
  /* opacity: 0.5; */
}

.tpc-subjects,
.tpc-days {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}

.tpc-subject,
.tpc-day {
  font-size: 14px;
  color: var(--darkText);
  margin: 4px 2.5px;
  text-align: center;
  white-space: nowrap;
  border: 2px solid var(--borderGrey);
  padding: 3px 10px;
  border-radius: 100px;
}

.tpc-subject.add {
  background: var(--primaryBlue);
  color: var(--mainWhite);
  border: 2px solid var(--primaryBlue);
  padding: 2px 12px 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tpc-subject.add:hover {
  transform: scale(1.04);
}

.tpc-add-tag-icon {
  width: 25px;
  height: 25px;
}

.tpc-container.editable .tpc-days {
  margin-bottom: 24px;
}

.tpc-connect-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 8px 16px;
  background: var(--primaryBlue);
  border: none;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 16px;
  color: var(--mainWhite);
}

.tpc-connect-button:disabled {
  background: var(--mainWhite);
  color: var(--primaryBlue);
  font-weight: bold;
  cursor: auto;
}

.tpc-connect-button:disabled:hover {
  transform: none;
}

.tpc-container.editable .tpc-connect-button {
  pointer-events: none;
  display: none;
}

.tpc-connect-button:hover {
  transform: scale(1.05);
}

.tpc-container.editable .tpc-connect-button:hover {
  transform: none;
}

.tpc-pfp-container {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.remove-tutor-pfp-icon {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0px;
  bottom: 20px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.add-tutor-pfp-container {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0px;
  bottom: 20px;
  transition: all 0.2s ease;
}

.add-tutor-pfp-input {
  display: none;
}

.add-tutor-pfp-icon {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.add-tutor-pfp-container:hover,
.remove-tutor-pfp-icon:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 700px) {
  .tpc-container.find-tutors {
    width: 100%;
  }

  .tpc-container.editable {
    max-width: 75%;
  }
}